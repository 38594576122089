import Link from 'next/link';
import styled from 'styled-components';

import { Col, Container, Row } from '@/components/core/flexbox';
import { socialMediaLinks } from '@/config/seo-meta';

import Copyright from './Copyright';
import SocialCol from './SocialCol';
import SocialWrapper from './SocialWrapper';
import Wrapper from './Wrapper';
import LinksCol from './LinksCol';
import MainLink from './MainLink';
import SubLink from './SubLink';
import Logo from './Logo';
import SocialLogo from './SocialLogo';
import Legal from './Legal';
import Portugal2020 from './Portugal2020';

import Twitter from '@swordhealth/ui-corporate/icons/system/twitter-x.svg';
import LinkedIn from '@swordhealth/ui-corporate/icons/system/linkedin.svg';
import Instagram from '@swordhealth/ui-corporate/icons/system/instagram.svg';
import Youtube from '@swordhealth/ui-corporate/icons/system/youtube.svg';
import Facebook from '@swordhealth/ui-corporate/icons/system/facebook.svg';

export const LINKS = [
  {
    name: 'Business',
    sublinks: [
      {
        name: 'Employers',
        href: '/business/employers',
      },
      {
        name: 'Health Plans',
        href: '/business/health-plans',
      },
      {
        name: 'Consultants & Brokers',
        href: '/business/consultants-brokers',
      },
      {
        name: 'ROI calculator',
        href: '/roi',
      },
      {
        name: 'Request Demo',
        href: '/request-demo/platform',
      },
    ],
  },
  {
    name: 'Individuals',
    sublinks: [
      {
        name: 'Individuals',
        href: '/individuals',
      },
      {
        name: 'Enroll',
        href: 'https://meet.swordhealth.com?utm_medium=referral&utm_source=sword_website',
      },
    ],
  },
  {
    name: 'Learn',
    sublinks: [
      {
        name: 'Newsroom',
        href: '/newsroom',
      },
      {
        name: 'Resources',
        href: '/resources',
      },
      {
        name: 'Patents',
        href: '/resources/patents',
      },
      {
        name: 'FAQs',
        href: '/faqs',
      },
    ],
  },
  {
    name: 'Solutions',
    sublinks: [
      {
        name: 'Thrive',
        href: '/solutions/thrive',
      },
      {
        name: 'Academy',
        href: '/solutions/academy',
      },
      {
        name: 'On-Call',
        href: '/solutions/on-call',
      },
      {
        name: 'Bloom',
        href: '/solutions/bloom',
      },
      {
        name: 'Move',
        href: '/solutions/move',
      },
      {
        name: 'Predict',
        href: '/solutions/predict',
      },
      {
        name: 'Atlas',
        href: '/solutions/atlas',
      },
    ],
  },
  {
    name: 'Company',
    sublinks: [
      {
        name: 'About',
        href: '/about-us',
      },
      {
        name: 'Foundation',
        href: '/foundation',
      },
      {
        name: 'Clinical Excellence',
        href: '/clinical-excellence',
      },
      {
        name: 'AI Care',
        href: '/ai',
      },
      {
        name: 'Health Equity',
        href: '/company/health-equity',
      },
      {
        name: 'Careers',
        href: '/company/careers',
      },
    ],
  },
  /*{
    name: 'Resources',
    sublinks: [
      {
        name: 'Newsroom',
        href: '/newsroom',
      },
      {
        name: 'Clinical studies',
        href: '/resources/clinical-studies',
      },
      {
        name: 'White papers',
        href: '/resources/whitepapers',
      },
      {
        name: 'Webinars',
        href: '/resources/webinars',
      },
      {
        name: 'Tech blog',
        href: '/resources/tech',
      },
      {
        name: 'Press',
        href: '/resources/press',
      },
    ],
  },*/
];

const Footer = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <SocialCol xs={12} lg={2}>
              <Link href="/" aria-label="Go to homepage">
                <Logo />
              </Link>
              <SocialWrapper>
                <SocialLogo
                  aria-label="Instagram"
                  href={socialMediaLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </SocialLogo>
                <SocialLogo
                  aria-label="Facebook"
                  href={socialMediaLinks.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </SocialLogo>
                <SocialLogo
                  aria-label="LinkedIn"
                  href={socialMediaLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </SocialLogo>
                <SocialLogo
                  aria-label="Youtube"
                  href={socialMediaLinks.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube />
                </SocialLogo>
                <SocialLogo
                  aria-label="Twitter"
                  href={socialMediaLinks.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </SocialLogo>
              </SocialWrapper>
              <Copyright>{`© ${new Date().getFullYear()} Sword Health, Inc.\nAll Rights Reserved`}</Copyright>
            </SocialCol>
            <Col xs={0} lg={1} />
            <LinksCol xs={12} lg={9}>
              <Line>
                {LINKS.map((mainLink) => (
                  <MainLink
                    key={mainLink.name}
                    name={mainLink.name}
                    href={mainLink.href}
                    newTab={mainLink.newTab}
                  >
                    {mainLink.sublinks?.map((subLink) => (
                      <SubLink
                        key={subLink.name}
                        name={subLink.name}
                        href={subLink.href}
                        newTab={subLink.newTab}
                      />
                    ))}
                  </MainLink>
                ))}
              </Line>
            </LinksCol>
          </Row>
        </Container>
      </Wrapper>
      <SubWrapper>
        <Portugal2020 />
        <Legal />
      </SubWrapper>
    </>
  );
};

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-wrap: wrap;
    > div {
      &:nth-child(2),
      &:first-child {
        margin-bottom: 48px;
      }

      &:nth-child(odd) {
        padding-right: 24px;
      }
    }
  }
`;

const SubWrapper = styled.div`
  padding: 48px 0;
  background: #edeae8;
`;

export default Footer;
